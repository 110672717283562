import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import BlockList from "../components/block-list"
import RichText from "../components/rich-text"
import Article from "../components/article"
import ContentFooter from "../components/content-footer"
import { getDescription, getMetaImageSrc, getTitle } from "../common/utils"
import useLinksTo from "../hooks/use-links-to"

export const Head = ({ data: { story }, location }) => (
  <SEO
    title={getTitle(story)}
    description={getDescription(story)}
    metaImage={getMetaImageSrc(story)}
    metaTwitterCardType={story.twitterSharePreviewType}
    pathname={location?.pathname}
  />
)

const CaseStory = ({ data: { story, next }, pageContext }) => {
  const { getCaseStoryLinkTo } = useLinksTo()

  return (
    <Layout
      pageContext={pageContext}
      footer={
        <ContentFooter
          title={next.title}
          subtitle="Go to next case"
          image={next.image}
          to={getCaseStoryLinkTo(next.slug)}
        />
      }
    >
      <Header
        title={story.title}
        subtitle={story.client}
        image={story.video || story.image}
      />
      {story.before && <BlockList data={story.before} />}
      {story.content && (
        <Article>
          <RichText content={story.content} />
        </Article>
      )}
      {story.after && <BlockList data={story.after} />}
    </Layout>
  )
}

export const query = graphql`
  query ($contentfulId: String!, $nextContentfulId: String!) {
    story: contentfulCaseStory(contentful_id: { eq: $contentfulId }) {
      title
      image {
        title
        gatsbyImageData(layout: FULL_WIDTH)
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      twitterSharePreviewType
      video {
        file {
          url
        }
      }
      client
      content {
        raw
      }
      before {
        __typename
        ... on Node {
          ... on ContentfulHeaderBlock {
            ...HeaderBlock
          }
          ... on ContentfulSectionBlock {
            ...SectionBlock
          }
          ... on ContentfulQuoteBlock {
            ...QuoteBlock
          }
          ... on ContentfulImageBlock {
            ...ImageBlock
          }
          ... on ContentfulOfficeBlock {
            ...OfficeBlock
          }
          ... on ContentfulStatisticsBlock {
            ...StatisticsBlock
          }
          ... on ContentfulLogosBlock {
            ...LogosBlock
          }
          ... on ContentfulImageLinkListBlock {
            ...ImageLinkListBlock
          }
          ... on ContentfulContactBlock {
            ...ContactBlock
          }
          ... on ContentfulHubSpotFormBlock {
            ...HubSpotFormBlock
          }
          ... on ContentfulComponentBlock {
            ...ComponentBlock
          }
          ... on ContentfulHyperlinkButtonBlock {
            ...HyperlinkButtonBlock
          }
          ... on ContentfulTableBlock {
            ...TableBlock
          }
          ... on ContentfulHubSpotMeetingBlock {
            ...HubSpotMeetingBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
        }
      }
      after {
        __typename
        ... on Node {
          ... on ContentfulHeaderBlock {
            ...HeaderBlock
          }
          ... on ContentfulSectionBlock {
            ...SectionBlock
          }
          ... on ContentfulQuoteBlock {
            ...QuoteBlock
          }
          ... on ContentfulImageBlock {
            ...ImageBlock
          }
          ... on ContentfulOfficeBlock {
            ...OfficeBlock
          }
          ... on ContentfulStatisticsBlock {
            ...StatisticsBlock
          }
          ... on ContentfulLogosBlock {
            ...LogosBlock
          }
          ... on ContentfulImageLinkListBlock {
            ...ImageLinkListBlock
          }
          ... on ContentfulContactBlock {
            ...ContactBlock
          }
          ... on ContentfulHubSpotFormBlock {
            ...HubSpotFormBlock
          }
          ... on ContentfulComponentBlock {
            ...ComponentBlock
          }
          ... on ContentfulHyperlinkButtonBlock {
            ...HyperlinkButtonBlock
          }
          ... on ContentfulTableBlock {
            ...TableBlock
          }
          ... on ContentfulHubSpotMeetingBlock {
            ...HubSpotMeetingBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
        }
      }
    }
    next: contentfulCaseStory(contentful_id: { eq: $nextContentfulId }) {
      title
      slug
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default CaseStory
